import React, { useEffect } from "react";
import { graphql } from "gatsby";

import { motion } from "framer-motion";

import { DefaultContainer } from "components/common/Structure";
import Team from "components/team";
import Colored from "components/colored";
import Footer from "components/footer";

const WhoWeAre = (props) => {
  //PROPS
  const {
    data: {
      page: {
        whoweare: { title, subtitle, tMember, ctitle, cinfo },
      },
    },
    setColor,
  } = props;
  //PROPS

  //DEFS
  const lang =
    props.pageContext.translations[0].language.code === "EN" ? "PT" : "EN";
  //DEFS

  //ABOUTANIM
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0, duration: 1 },
    },
    exit: {
      opacity: 0,
      y: 0,
      transition: { ease: "easeInOut", duration: 1 },
    },
  };
  //ABOUTANIM

  useEffect(() => {
    setColor(true);
  }, []);

  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <DefaultContainer>
          <Team title={title} subtitle={subtitle} tMember={tMember} />
          <Colored ctitle={ctitle} cinfo={cinfo} />
        </DefaultContainer>
        <Footer lang={lang} />
      </motion.div>
    </>
  );
};

export const query = graphql`
  query WhoWeAre($id: String) {
    page: wpPage(id: { eq: $id }) {
      whoweare {
        title
        subtitle
        ctitle
        tMember {
          mTitle
          name
          smedia {
            sLink {
              title
              url
              target
            }
            sImage {
              altText
              mediaItemUrl
            }
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 500
                  quality: 100
                  formats: NO_CHANGE
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default WhoWeAre;
